.next__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;
}

.next__title {
  margin-right: 10px;
}

.next__items {
  @include reset_list_style();

  &_videos {
    .next__item {
      margin-bottom: 40px;

      border-bottom: 1px solid $gray_border;
    }
  }
}

.next__item {
  margin-bottom: 56px;
}