.flexy-align {
	display: flex;
	align-items: center;
}

.event_item {
	display: flex;
	&:first-child {
		margin-right: 10px;
	}
	&__date {
		&_day {
			color: $red;
			font-size: 38px;
			font-weight: bold;
			line-height: initial;
		}
		&_month {
			color: $red;
			font-weight: bold;
			font-size: 14px;
		}
	}
	&__info {
		display: flex;
		flex-direction: column;
		width: 100%;
		&_title {
			font-size: 18px;
			font-weight: bold;
			color: $black_text;
		}
		&_time {
			font-size: 13px;
			color: $black_text;
			@extend .flexy-align;
		}
		&_location {
			@extend .event_item__info_time;
			color: $red;
		}
		&_desc {
			margin-left: 5px;
		}
	}

	&__btn {
		text-align: right;
		&_container {
			padding: 0 15px;
		}
	}
}

.event_italic_subtitle {
	font-style: italic;
	color: $black_text;
	font-style: 13px;
	margin-top: 20px;
}

.event_no_events {
	font-style: 13px;
	color: $blue;
}

.event_btn {
	@extend .btn;
	@extend .btn_theme_white;
	height: 56px;
}

.events {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
		padding-top: 32px;
	}
	&__search_field {
		height: 50px;
		border: none;
		border-bottom: 1px solid $gray_light;
		padding-right: 21px;
		&_icon {
			position: absolute;
			right: 0;
			top: 15px;
		}
		&_container {
			position: relative;
			margin-right: 40px;
		}
	}
	&__actions {
		display: flex;
	}
	&__toggler {
		display: flex;
		cursor: pointer;
		p {
			margin: 0 10px 0 0;
		}
	}
}

.template-details-trigger {
	cursor: pointer;
	&:hover {
		opacity: 0.8;
	}
}

.events_filter_container {
	margin-top: 24px;
	display: grid;
	grid-template-columns: 3fr 3fr 3fr 1fr;
	grid-column-gap: 20px;
}

@include media-breakpoint-down(sm) {
	.event_item {
		flex-direction: column;
		// grid-template-columns: auto auto;
		// grid-template-rows: 1fr 2fr;
		// grid-template-areas:
		// 	"date btn"
		// 	"info info";
		&__info {
			margin-left: 0;
		}
		&__btn {
			margin-top: 1rem;
			margin-bottom: 1rem;
			text-align: left;
		}
	}
	.rrule_margin_top {
		margin-top: 24px;
	}
	.events_filter_container {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		button {
			width: 100%;
		}
	}
}
