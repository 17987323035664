@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/swiper/swiper.scss";
@import "../../node_modules/react-toastify/dist/ReactToastify.min.css";
@import "../../node_modules/pell/dist/pell.min.css";
@import "../../node_modules/react-bootstrap-typeahead/css/Typeahead.css";
@import "./global/index";
@import "./blocks/index";

html,
body {
	height: 100%;
}

.form-control[readonly].readonly-no-bg {
	background-color: #fff;
}

input[type="radio"],
svg {
	&:hover {
		cursor: pointer;
	}
}

.modal-open {
	padding-right: 0px !important;
	overflow: auto !important;
}

.modal {
	.datetimerange_picker_controls {
		.btn {
			min-width: 128px;
		}
	}

	.day-btn {
		min-width: auto;
		max-width: 100px;
	}
}

#root {
	display: flex;
	height: 100vh;
	flex-direction: column;

	main {
		flex: 1;
	}
}

a {
	cursor: pointer;
}

.table-full-width {
	min-width: 100%;

	th,
	td {
		padding: 0.75rem;
	}

	thead th {
		color: #212529;
	}
}

a:not([href]).js-back-link {
	color: #9c1c21;
}

.form-validation-error {
	display: inline-block;
	text-align: center;
	margin-top: 6px;
	color: #9c1c21;
	&_sm {
		@extend .form-validation-error;
		margin-top: 0;
		font-size: 14px;
	}
}

button {
	.spinner-border-sm {
		margin-left: 5px;
	}
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
		white no-repeat 98.5% !important;
	/* !important used for overriding all other customisations */
	background: url("https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png")
		white no-repeat calc(100% - 10px) !important;
	/* Better placement regardless of input width */
}

.selected-file-name {
	display: block;
	margin-top: 6px;
	text-align: center;
}

.video__content_preview {
	border-radius: 8px;
	// height: 400px;
}

.swiper-pagination-bullet {
	cursor: pointer;
}

.video__share {
	border: none;
}

.form-spinner {
	margin-bottom: 24px;
}

.form-control-editor {
	color: #333333;
	border: 1px solid #a1a1a1;
	border-radius: 0.25rem;

	.pell-actionbar {
		padding-top: 0.5rem !important;
		padding-bottom: 0.3rem !important;
		border-bottom: 1px solid #a1a1a1;
	}
}

.post-item__text {
	max-height: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 0 10px;
	color: #a1a1a1;

	p {
		margin: 0 0 0;
	}
}

.edit-post-modal-body,
.edit-post-modal-footer {
	max-width: 100%;
	padding: 2rem 2rem;
}

.wide-modal-body-without-shadow {
	@extend .edit-post-modal-body;
	padding: 1rem;
	box-shadow: none;
}

.chat__rooms::-webkit-scrollbar-track,
.chat__mesages::-webkit-scrollbar-track,
.infinite-scroll-component::-webkit-scrollbar-track,
.recent_activities__items::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
	border-radius: 10px;
}

.chat__rooms::-webkit-scrollbar,
.chat__mesages::-webkit-scrollbar,
.infinite-scroll-component::-webkit-scrollbar,
.recent_activities__items::-webkit-scrollbar {
	width: 2px;
	background-color: #f5f5f5;
}

.chat__rooms::-webkit-scrollbar-thumb,
.chat__mesages::-webkit-scrollbar-thumb,
.infinite-scroll-component::-webkit-scrollbar-thumb,
.recent_activities__items::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0.44, #d92930),
		color-stop(0.72, #bd262c),
		color-stop(0.86, #9c1c21)
	);
}

.custom-scroll-track::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #f5f5f5;
	border-radius: 10px;
}

.custom-scroll-track::-webkit-scrollbar {
	width: 2px;
	background-color: #f5f5f5;
}

.custom-scroll-track::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0.44, #d92930),
		color-stop(0.72, #bd262c),
		color-stop(0.86, #9c1c21)
	);
}

.chat__content,
.chat__welcome {
	padding: 0 2px;
}

.chat__welcome {
	background-color: #f8f8f8;

	p {
		padding: 18px 16px 13px 24px;
		background-color: #fff;
		border-radius: 24px;
	}

	@include media-breakpoint-up(lg) {
		p {
			max-width: 404px;
		}
	}

	@include media-breakpoint-down(md) {
		min-height: 200px;
	}
}

.form-check-label {
	&:hover {
		cursor: pointer;
	}
}

.reset-password {
	padding-top: 63px;
	padding-bottom: 79px;
}

.login-modal-action {
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.auth_modal_switch_p {
	color: $red;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.more_recurring-giving_p {
	color: $red;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.rbt-loader {
	color: $red;
}

.search-bar {
	.rbt {
		max-width: 100%;

		.search-filter {
			display: flex;
			justify-content: left;
			padding: 0.5rem 1rem 0.5rem 1.5rem;
		}

		.form-control {
			height: calc(1.5em + 0.75rem + 2px);
		}

		.rbt-menu-pagination-option {
			text-align: left;
		}
	}

	.search-item-date {
		display: none;
	}
}

.phone_input_config {
	width: 100% !important;
	border-color: $gray_light !important;
	font-family: "Montserrat", $font-family-base;
	color: $black_text;
}

@include media-breakpoint-up(md) {
	.search-bar {
		margin-left: auto;

		.rbt {
			max-width: 100%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.search-bar {
		margin-left: auto;

		.rbt {
			max-width: 100%;
		}

		.search-item-date {
			display: inline;
		}
	}
}

.card-modal-mody {
	min-height: auto;
	box-shadow: none;
}

.stripe-payment-form {
	form {
		width: 30vw;
		align-self: center;
		box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
			0px 2px 5px 0px rgba(50, 50, 93, 0.1),
			0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
		border-radius: 7px;
		padding: 40px;
	}

	input {
		border-radius: 6px;
		margin-bottom: 6px;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		max-height: 44px;
		font-size: 16px;
		width: 100%;
		background: white;
		box-sizing: border-box;
	}

	.result-message {
		line-height: 22px;
		font-size: 16px;
	}

	.result-message a {
		color: #9c1c21;
		font-weight: 600;
		text-decoration: none;
	}

	.hidden {
		display: none;
	}

	.card-error {
		color: $red;
		font-size: 16px;
		line-height: 20px;
		margin-top: 12px;
		text-align: center;
	}

	#card-element,
	#add-form-card-element {
		border-radius: 4px 4px 0 0;
		padding: 12px;
		border: 1px solid rgba(50, 50, 93, 0.1);
		max-height: 44px;
		width: 100%;
		background: white;
		box-sizing: border-box;
	}

	#payment-request-button {
		margin-bottom: 32px;
	}

	/* Buttons and links */
	button {
		background: #9c1c21;
		font-family: Arial, sans-serif;
		color: #ffffff;
		border-radius: 0 0 4px 4px;
		border: 0;
		padding: 12px 16px;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		display: block;
		transition: all 0.2s ease;
		box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
		width: 100%;
	}

	button:disabled {
		opacity: 0.75;
		cursor: not-allowed;
	}

	// button:focus {
	// 	outline: none !important;
	// }

	/* spinner/processing state, errors */
	.spinner,
	.spinner:before,
	.spinner:after {
		border-radius: 50%;
	}

	.spinner {
		color: #ffffff;
		font-size: 22px;
		text-indent: -99999px;
		margin: 0px auto;
		position: relative;
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 2px;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}

	.spinner:before,
	.spinner:after {
		position: absolute;
		content: "";
	}

	.spinner:before {
		width: 10.4px;
		height: 20.4px;
		background: #9c1c21;
		border-radius: 20.4px 0 0 20.4px;
		top: -0.2px;
		left: -0.2px;
		-webkit-transform-origin: 10.4px 10.2px;
		transform-origin: 10.4px 10.2px;
		-webkit-animation: loading 2s infinite ease 1.5s;
		animation: loading 2s infinite ease 1.5s;
	}

	.spinner:after {
		width: 10.4px;
		height: 10.2px;
		background: #9c1c21;
		border-radius: 0 10.2px 10.2px 0;
		top: -0.1px;
		left: 10.2px;
		-webkit-transform-origin: 0px 10.2px;
		transform-origin: 0px 10.2px;
		-webkit-animation: loading 2s infinite ease;
		animation: loading 2s infinite ease;
	}

	@keyframes loading {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@media only screen and (max-width: 600px) {
		form {
			width: 80vw;
		}
	}
}

.giving-payment-methods {
	padding: 0;
}

.giving-payment-methods__item {
	cursor: pointer;
	padding: 15px 0;
	border-bottom: 2px solid #f8f8f8;

	&:hover {
		opacity: 0.8;
	}

	img {
		max-width: 50%;
		height: auto;
	}
}

.giving-payment-methods__item.active {
	opacity: 0.8;
	font-weight: 600;
}

.chartjs-tooltip {
	opacity: 1;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	color: white;
	border-radius: 3px;
	-webkit-transition: all 0.1s ease;
	transition: all 0.1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 5px;
}
