.table {
	width: 100%;
	// min-width: 700px;

	thead {
		th {
			font-weight: 400;
			color: $gray_light;

			border: none;
		}
	}

	th,
	td {
		padding: 40px 0.5rem;
		vertical-align: middle;

		border-top: 2px solid $gray_border_db;

		&:last-child {
			text-align: right;
		}
	}
}

.roles_table {
	@extend .table;
	th,
	td {
		padding: 1rem 0.5rem;
		width: calc(100% / 3);
	}
}

.members_table {
	@extend .table;
	th,
	td {
		padding: 1rem 0.5rem;
		// width: calc(100% / 3);
	}
}

.songs_table {
	@extend .table;
	th,
	td {
		padding: 0.5rem;
	}
}
