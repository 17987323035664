input[type="range"] {
	width: 100%;
	margin: 8.5px 0;
	background-color: transparent;
	-webkit-appearance: none;
}
input[type="range"]:focus {
	outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
	background: $red;
	border-radius: 20px;
	width: 100%;
	height: 3px;
	cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
	margin-top: -8.6px;
	width: 20px;
	height: 20px;
	background: #ffffff;
	border: 1px solid $red;
	border-radius: 10px;
	cursor: pointer;
	-webkit-appearance: none;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
	background: #b22026;
}
input[type="range"]::-moz-range-track {
	background: $red;
	border-radius: 20px;
	width: 100%;
	height: 3px;
	cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
	width: 20px;
	height: 20px;
	background: #ffffff;
	border: 1px solid $red;
	border-radius: 10px;
	cursor: pointer;
}
input[type="range"]::-ms-track {
	background: transparent;
	border-color: transparent;
	border-width: 9.5px 0;
	color: transparent;
	width: 100%;
	height: 3px;
	cursor: pointer;
}
input[type="range"]::-ms-fill-lower {
	background: #86181c;
	border: 0.1px solid #010101;
	border-radius: 40px;
}
input[type="range"]::-ms-fill-upper {
	background: $red;
	border-radius: 40px;
}
input[type="range"]::-ms-thumb {
	width: 20px;
	height: 20px;
	background: #ffffff;
	border: 1px solid $red;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 0px;
	/*Needed to keep the Edge thumb centred*/
}
input[type="range"]:focus::-ms-fill-lower {
	background: $red;
}
input[type="range"]:focus::-ms-fill-upper {
	background: #b22026;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
	/* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
	input[type="range"] {
		margin: 0;
		/*Edge starts the margin from the thumb, not the track as other browsers do*/
	}
}
