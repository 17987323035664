.wizard {
	&_title {
		font-family: "Montserrat", $font-family-base;
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		&_description {
			font-family: "Montserrat", $font-family-base;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: black;
			opacity: 0.4;
		}
	}

	&_time_item {
		border: 1px solid $gray_border_chat;
		padding: 24px 15px 0 15px;
		border-radius: 1rem;
	}
}

.block_shadow {
	box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
}

.events_calendar {
	&_header {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: "Montserrat" $font-family-base;
		font-weight: 600;
		font-size: 18px;
		padding-top: 10px;
		&_control_arrow {
			width: 50px;
		}
	}
	&_day_names {
		display: grid;
		width: 100%;
		grid-template-columns: repeat(7, 1fr);
		border-bottom: 1px solid #00000034;
		padding: 10px 0;
		margin-bottom: 10px;
		&_item {
			font-size: 13px;
			opacity: 0.4;
			color: black;
			font-weight: 600;
			text-align: center;
		}
	}
	&_day_wrapper {
		font-size: 13px;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		width: 40px;
		height: 40px;
		justify-self: center;
		position: relative;
		cursor: pointer;
		&_current_day {
			background: $red;
			border-radius: 50%;
			color: white;
		}
		&_selected {
			@extend .events_calendar_day_wrapper_current_day;
			background-color: lighten($color: $red, $amount: 30%);
		}
		&_mark {
			width: 5px;
			height: 5px;
			border-radius: 5px;
			background-color: $red;
			position: absolute;
			top: 10px;
			right: 5px;
		}
		&_white_mark {
			@extend .events_calendar_day_wrapper_mark;
			background-color: white;
		}
	}
	&_disabled_day_wrapper {
		@extend .events_calendar_day_wrapper;
		opacity: 0.4;
	}
	&_days {
		display: grid;
		width: 100%;
		height: 100%;
		grid-template-columns: repeat(7, minmax(0, 1fr));
	}
	&_button {
		background-color: $red;
		padding: 10px;
		color: white;
		border-radius: 0.25rem;
		transition: all 0.2s;
		width: -moz-fit-content;
		width: -webkit-fit-content;
		align-self: center;
		margin-bottom: 10px;
		&:hover {
			text-decoration: none;
			color: white;
			background-color: lighten($color: $red, $amount: 7%);
		}
	}
}

.upcoming_events {
	&_header {
		font-family: "Montserrat" $font-family-base;
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		// margin: 0;
	}
	&_card_header {
		font-family: "Montserrat" $font-family-base;
		font-style: normal;
		font-weight: 600;
		font-size: 15px;
		margin: 0;
	}
	&_card_date {
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		color: #4f4f4f;
		margin: 0;
	}
	&_card_full_date {
		font-size: 13px;
		color: $black_text;
		opacity: 0.8;
		margin: 0;
		&_span {
			font-size: 12px;
			color: $black_text;
			opacity: 0.5;
		}
	}
	&_container {
		overflow-y: auto;
		max-height: 30vh;
		padding: 5px;
	}
}

.plans_search_input {
	margin: 0 0 0 20px;
	height: 100%;
	width: 100%;
	border: none;
	&:focus {
		outline: 1px solid;
	}
}

.main_plans_content {
	padding: 20px;
	&_header {
		font-weight: 600;
		font-size: 22px;
		font-family: "Montserrat" $font-family-base;
		margin: 0;
	}
}

.service_type_item {
	&_header {
		margin: 0;
		font-size: 16px;
		font-weight: 600;
		text-decoration: underline;
		a {
			color: inherit;
			&:hover {
				// text-decoration: none;
				opacity: 0.8;
			}
		}
	}
	&_static {
		padding: 10px 0;
		border-bottom: 1px solid $gray_border;
	}
	&_collapse {
		@extend .dashboard__control_sm;
		width: 40px;
		// height: 30px;
	}
}

.sub_plan_item {
	width: 100%;
	border: 1px solid $gray_placeholder;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.25rem;
	padding: 15px;
	margin: 15px 0;
	p {
		margin: 0;
		font-size: 13px;
	}
	&_link {
		@extend .events_calendar_button;
		margin: 0;
		padding: 5px;
	}
}

.plan_action {
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 10px;
	&_header {
		p {
			margin: 0;
			font-family: "Montserrat" $font-family-base;
			font-weight: 600;
			font-size: 16px;
		}
		&_collapse_icon {
			width: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
	}

	&_description {
		background-color: #f5f5f5;
		border-radius: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		margin: 10px 0;
		p {
			margin: 0;
			font-size: 16px;
			color: #8e90a6;
		}
	}

	&_button {
		height: 30px;
		width: 30px;
		padding: 0;
		background-color: $red;
		border: none;
		border-radius: 0.5rem;
		svg {
			width: 15px;
			height: 15px;
			margin: 0 auto;
		}
	}

	&_added_item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		p,
		a {
			margin: 0;
			font-size: 14px;
			font-weight: 300;
			color: $gray_light;
			// max-width: 70%;
			// width: 80%;
		}
	}

	&_added_item_button {
		height: 20px;
		width: 50px;
		background-color: transparent;
		border: none;
		padding: 0;
		color: $gray_placeholder;
		svg {
			width: 100%;
			height: 100%;

			fill: currentColor;
		}
		&_sm {
			@extend .plan_action_added_item_button;
			height: 20px;
			width: 20px;
		}
	}
}

.plans_back {
	width: 100%;
	height: 50px;
	padding: 0 0 0 20px;
	p {
		margin: 0;
		font-size: 16px;
		margin-left: 15px;
	}
}

.plans_page_content {
	padding: 20px 30px;
	&_header {
		border-bottom: 1px solid $gray_border;
		padding: 10px 0;
		&_type {
			margin: 0;
			font-weight: 600;
			font-size: 16px;
			font-family: "Montserrat" $font-family-base;
		}
		&_date {
			font-size: 13px;
			color: #4f4f4f;
			margin: 0;
		}
	}
}

.plan_item {
	&_header {
		margin: 0;
		font-size: 16px;
		// font-weight: 600;
	}
	&_collapse {
		background-color: transparent;
		border: none;
		@extend .dashboard__control_sm;
		width: 40px;
		svg {
			width: 100%;
		}
	}

	&_content {
		display: flex;
		flex-wrap: wrap;
		background-color: #f5f5f5;
		padding: 20px 10px 10px 10px;
	}

	&_info {
		min-width: 45%;
		margin: 10px;
	}
}

.master_calendar {
	&_toolbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;
		&_controls_left {
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: "Montserrat" $font-family-base;
			font-weight: 600;
			font-size: 18px;
			&_control_arrow {
				width: 50px;
			}
		}
	}
}

.add_song_modal {
	&_popular_table_header {
		font-size: 14px;
		color: $gray_placeholder;
		margin: 0;
		&_number {
			margin-left: 3rem;
			margin-right: 1rem;
		}
	}
	&_pagination_buttons {
		color: $gray_placeholder;
		font-weight: bold;
		font-size: 16px;
	}
	&_second_step_description {
		font-size: 16px;
		color: $gray_light;
		width: 70%;
		margin: 0;
	}
}

.service_time_add_time_button {
	background-color: transparent;
	border: none;
	color: $red;
	display: flex;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;
	p {
		margin: 0;
	}
}

.action_sub_category_title {
	margin: 0;
	font-size: 12px;
	font-weight: bold;
}

.plan_upload_container {
	width: 100%;
	height: 250px;
	background-color: $gray_border_db;
	margin-top: 10px;
	text-align: center;
	&_drop_active {
		background-color: $gray_light;
	}
	p {
		padding-top: 100px;
	}
}

.song_table_title {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
	&_loader {
		position: absolute;
		right: 15px;
		top: 15px;
	}
}

@include media-breakpoint-down(sm) {
	.wizard {
		&_time_item {
			padding: 24px 15px 24px 15px;
		}
	}
	.master_calendar {
		&_toolbar {
			flex-direction: column;
		}
	}
	.add_song_modal {
		&_second_step_description {
			width: 100%;
		}
	}
}
