.chat__rooms {
	padding: 0;
	// max-height: 275px;

	border-top: 2px solid $gray_border_db;
	border-bottom: 2px solid $gray_border_db;
	overflow: hidden;
}

.chat__room {
	display: flex;
	padding: 15px;

	border-bottom: 2px solid $gray_border_chat;
	color: inherit;

	transition: opacity 0.2s ease;
	transition: background-color 0.4s;
	&__selected {
		background-color: #9c1c2114;
	}

	&:hover {
		text-decoration: none;
		color: inherit;
		background-color: #bd1c2159;
	}

	&:active {
		text-decoration: none;
		color: inherit;

		opacity: 0.7;
	}

	&:last-child {
		border-bottom: none;
	}
}

.chat__name {
	display: flex;
	justify-content: space-between;
}

.chat_quick_info {
	width: 90%;
	padding-left: 15px;
}

.chat__counter {
	display: flex;
	justify-content: center;
	align-items: center;
	// margin-top: 2px;
	margin: 2px 5px;
	min-width: 20px;
	height: 20px;

	font-size: 13px;
	line-height: 1;
	font-weight: 700;
	color: $white;
	flex-shrink: 0;

	background-color: $yellow;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: -10px;
}

.chat__when {
	display: block;
	margin-bottom: 10px;

	font-size: 13px;
	color: $gray_light;
}

.chat__preview {
	margin: 0;
	width: 90%;
	font-size: 13px;
	color: $gray_light;
}

.chat__content {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0;
	// max-height: 725px;
	background-color: $gray_border_db;
}

.chat__mesages {
	padding: 26px 15px;
	// min-height: 200px;
	max-height: 725px;
	overflow: auto;

	div.messages-scroll {
		display: flex;
		flex-direction: column;
	}
}

.chat__mesage {
	margin: 0;
	margin-bottom: 26px;
	padding: 18px 16px 13px 24px;

	font-size: 14px;
	color: $gray_light;

	background-color: $white;
	border-radius: 24px;

	&:last-child {
		margin-bottom: 0;
	}

	&_in {
		margin-right: 15px;

		border-bottom-left-radius: 0;
	}

	&_out {
		margin-left: 15px;

		border-bottom-right-radius: 0;
	}
}

.chat__type-area {
	border: 2px solid $gray_border_db;

	textarea {
		display: block;
		padding: 23px 26px;
		width: 100%;

		font-size: 13px;
		line-height: 1.5;

		background-color: $white;
		border: none;

		transition: all 0.2s ease;
		resize: none;

		&::placeholder {
			color: $gray_placeholder;
		}

		&:active,
		&:focus {
			padding: 23px 15px;
		}
	}
}

.chat_empty_requests_placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.request_toolbar {
	&_container {
		display: flex;
		padding: 10px;
	}
	&_loader_place_without_plus {
		position: absolute;
		right: 20px;
		top: 25px;
	}
	&_loader_place_with_plus {
		position: absolute;
		right: 60px;
		top: 25px;
	}
	&_plus {
		width: 40px;
		display: flex;
		justify-content: center;
	}
}

@include media-breakpoint-up(md) {
	.chat {
		margin: 0;
		@include shadow();
		height: 100%;
		// max-height: 800px;
	}

	.chat__rooms {
		max-height: 100%;

		border: none;
	}

	.chat__room {
		&:last-child {
			border-bottom: 2px solid $gray_border_chat;
		}
	}

	.chat__content {
		max-height: 100%;
	}

	// 	.chat__mesages {
	// 		max-height: initial;
	// 	}
}

@include media-breakpoint-up(lg) {
	.chat__mesages {
		padding: 26px 24px;
		max-height: 725px;
	}

	.chat__mesage {
		width: 100%;
		max-width: 404px;

		&_in {
			align-self: flex-start;
			margin-right: 0;
		}

		&_out {
			align-self: flex-end;
			margin-left: 0;
		}
	}
}

.chat__mesage {
	.chat__mesage-metadata {
		color: #333333;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1px;

		.chat__mesage-metadata-author {
			// word-wrap: break-word;
			max-width: 60%;
		}

		.chat__mesage-metadata-date {
			text-align: right;
			max-width: 41%;
		}
	}
}
