.hero__item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: 100vh;

  text-align: center;
  color: $white;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;

  &_welcome {
    background-image: url("../img/bg_hero_0.jpg");

    @include for_retina() {
      background-image: url("../img/bg_hero_0@2x.jpg");
    }
  }

  &_help {
    background-image: url("../img/bg_hero_1.jpg");

    @include for_retina() {
      background-image: url("../img/bg_hero_1@2x.jpg");
    }
  }

  &_join {
    background-image: url("../img/bg_hero_2.jpg");

    @include for_retina() {
      background-image: url("../img/bg_hero_2@2x.jpg");
    }
  }
}

.hero__notice {
  margin: 0;
  margin-bottom: 25px;

  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
}

.hero__title {
  margin-bottom: 50px;

  font-family: "Playfair Display", "Georgia", serif;
  font-size: 40px;
  letter-spacing: 0.02em;
}

.hero__link {
  min-width: 202px;
}

@include media-breakpoint-up(md) {
  .hero__notice {
    font-size: 24px;
  }

  .hero__title {
    margin-bottom: 72px;

    font-size: 72px;
  }
}

@include media-breakpoint-up(xl) {
  .hero__item {
    padding-bottom: 30px;
    min-height: 809px;
    height: auto;
  }

  .hero__notice {
    margin-bottom: 22px;
  }

  .hero__title {
    line-height: 1;
  }
}