.user-details {
	margin: auto;
	max-width: 740px;
}

.user-details__title {
	font-size: 22px;
	font-weight: 600;

	// margin-bottom: 30px;
}

@include media-breakpoint-up(md) {
	.user-details {
		// padding: 90px 15px 55px;
	}

	// .user-details__title {
	// 	margin-bottom: 60px;
	// }
}

@include media-breakpoint-up(lg) {
	.user-details {
		// padding: 60px 15px 55px;
	}
}
