.social__list {
	@include reset_list_style();

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -12px;
	justify-content: flex-end;
}

.social__item {
	margin: 12px;
}

.social__link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 66px;
	height: 66px;

	background-color: rgba($color: $white, $alpha: 0.3);
	border-radius: 50%;

	transition: all 0.2s ease;

	&_tw {
		svg {
			width: 42px;
			height: 34px;
		}

		&:active {
			background-color: $social_tw;
		}
	}

	&_fb {
		svg {
			width: 16px;
			height: 34px;
		}

		&:active {
			background-color: $social_fb;
		}
	}

	&_yt {
		svg {
			width: 31px;
			height: 21px;
		}

		&:active {
			background-color: $social_yt;
		}
	}
}

@include media-breakpoint-up(lg) {
	.social__link {
		&:active {
			opacity: 0.7;
		}

		&_tw {
			&:hover {
				background-color: $social_tw;
			}
		}

		&_fb {
			&:hover {
				background-color: $social_fb;
			}
		}

		&_yt {
			&:hover {
				background-color: $social_yt;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.social__list {
		justify-content: flex-start;
	}
}