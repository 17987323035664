html {
  box-sizing: border-box;
  height: 100%;

  scroll-behavior: smooth;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  min-height: 100%;

  font-family: "Montserrat", $font-family-base;
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
  color: $black_text;
}

img,
video,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
}

.visually-hidden {
  @include visually_hidden();
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: 1310px;
  }
}