.cards {
  position: relative;

  margin: 0 auto;
  padding-bottom: 40px;
  max-width: 379px;
}

.cards__swiper {
  margin: 0 auto 34px;
  width: 100%;
  max-width: 227px;
}

.card__add {
  display: block;
  margin: 0 auto;
  padding: 0.5rem;

  color: $gray_light;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  &:active {
    color: $red;
  }
}

.cards__btn {
  position: absolute;
  top: 50px;

  display: block;
  margin: 0;
  padding: 5px;
  width: 22px;
  height: 31px;
  color: $gray_light;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  svg {
    width: 100%;
    height: 100%;
  }

  &_prev {
    left: -10px;

    transform: rotate(180deg);
  }

  &_next {
    right: -10px;
  }

  &:active {
    color: $red;
  }
}

@include media-breakpoint-up(md) {
  .cards {
    padding-bottom: 0;
  }


  .cards__swiper {
    margin: 0 auto 58px;
  }

  .cards__btn {
    &_prev {
      left: -20px;
    }

    &_next {
      right: -20px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card__add {

    &:hover,
    &:focus {
      color: $red;
    }

    &:active {
      color: transparentize($color: $red, $amount: 0.3);
    }
  }

  .cards__btn {

    &:hover,
    &:focus {
      color: $red;
    }

    &:active {
      color: transparentize($color: $red, $amount: 0.3);
    }
  }
}