.nav-link {
	display: flex;
	align-items: center;
	padding: 0.5rem;

	color: $gray_light;

	transition: color 0.2s ease;

	&:hover {
		text-decoration: none;
		color: $gray_light;
	}

	&:active {
		text-decoration: none;
		color: rgba($color: $red, $alpha: 0.7);
	}

	&.active {
		font-weight: 600;
		color: $red;
	}
}

.nav-link-alt {
	display: flex;
	align-items: center;
	padding-left: 0.5rem;

	color: $gray_light;
	width: 250px;
	transition: color 0.2s ease;

	&:hover {
		text-decoration: none;
		color: $red;
	}

	&:active {
		text-decoration: none;
		color: rgba($color: $red, $alpha: 0.7);
	}

	&.active {
		font-weight: 600;
		color: $red;
	}
}

.nav-icon {
	display: flex;
	align-items: center;
	margin: 16px 25px 16px 16px;
	width: 31px;
	height: 28px;

	color: inherit;

	svg {
		width: 100%;
		height: 100%;

		fill: currentColor;
	}

	&_stroke {
		svg {
			fill: none;
			stroke: currentColor;
		}
	}

	&_sm {
		padding-left: 3px;

		svg {
			width: 24px;
			height: 24px;
		}
	}
}

.join-us-dropdown-item {
	font-size: 16px;
	font-family: "Montserrat", $font-family-base;
	font-weight: 600;
	color: $black_text !important;
	padding: 1rem;
	&:active {
		color: white !important;
	}
}

@include media-breakpoint-up(lg) {
	.nav-link {
		font-size: 18px;

		&:hover {
			text-decoration: none;
			color: rgba($color: $red, $alpha: 0.7);
		}

		&:active {
			text-decoration: none;
			color: rgba($color: $red, $alpha: 0.5);
		}
	}
}
