.dashboard_scheduling {
	&_header {
		&_tab {
			@extend .btn;
			background-color: $gray_border_db;
			color: $red;
			border-top-left-radius: 1rem;
			border-top-right-radius: 1rem;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			margin-right: 5px;
			&_active {
				@extend .dashboard_scheduling_header_tab;
				background-color: $red;
				color: $white;
			}
		}
	}

	&_group_item {
		&_header {
			background-color: $gray_border_chat;
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 60px;
			padding: 10px 15px;
			p {
				margin: 0;
				font-size: 18px;
			}
		}
		&_content {
			&_label {
				margin: 0;
				font-size: 14px;
				color: $gray_light;
			}
			&_desc_value {
				@extend .dashboard_scheduling_group_item_content_label;
				color: $black_text;
			}
			// height: 300px;
		}
		&_indicator {
			width: 40px;
			height: 25px;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-self: center;
			&_green {
				@extend .dashboard_scheduling_group_item_indicator;
				background-color: #bbf3d8;
			}
			&_gray {
				@extend .dashboard_scheduling_group_item_indicator;
				background-color: $gray_border_chat;
			}
		}
	}
}

.group_page {
	&_placeholder {
		&_header {
			font-weight: bold;
			color: $gray_light;
		}
		&_step {
			color: $gray_light;
		}
	}
}

.scheduling_filters {
	&_switch {
		cursor: pointer;
		margin: 0 10px;
		font-weight: 600;
		font-size: 20px;
		&_count {
			color: $red;
		}
	}
	&_grid-container {
		padding: 20px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr auto;
		gap: 1px 1rem;
		width: 550px;
		grid-template-areas: "name name name name" "select select status status" "tags tags tags tags";
	}
	&_name {
		grid-area: name;
	}

	&_select {
		grid-area: select;
	}

	&_status {
		grid-area: status;
	}

	&_tags {
		grid-area: tags;
		display: flex;
		flex-wrap: wrap;
	}
	&_container {
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.tag {
	// padding: 0px 10px;
	// border-radius: 10px;
	margin: 5px 5px;
	height: 30px;
	width: max-content;
	// background-color: #dad7fe;
	display: flex;
	font-size: 14px;
	&_group {
		background-color: #dad7fe;
		padding: 3px 10px;
		border-bottom-left-radius: 10px;
		border-top-left-radius: 10px;
		border-right: 1px solid $gray_light;
	}
	&_instance {
		background-color: #dad7fe;
		padding: 3px 10px;

		display: flex;
		// span {
		// 	margin-right: 10px;
		// }
	}
	&_close {
		padding: 6px;
		width: 30px;
		background-color: #dad7fe;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}
}

.filter_pane_bg {
	background-color: $gray_border_chat;
}

@include media-breakpoint-down(sm) {
	.scheduling_filters {
		&_grid-container {
			padding: 20px;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-rows: 1fr 1fr auto;
			gap: 1px 1rem;
			width: 95vw;
			grid-template-areas: "name name name name" "select select select select" "status status status status" "tags tags tags tags";
		}
	}
}
