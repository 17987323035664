.all-videos__list {
  @include reset_list_style();

  margin-right: -15px;
  margin-left: -15px;
}

@include media-breakpoint-up(md) {
  .all-videos {
    padding-top: 63px;
    padding-bottom: 79px;
  }

  .all-videos__title {
    margin-bottom: 88px;
  }

}