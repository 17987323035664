.img-area {
  &_filled {
    margin-bottom: 2rem;

    &>button {
      display: none;
    }

    .img-area__content {
      display: block;
    }
  }
}

.img-area__img {
  max-width: 280px;

  img {
    margin-bottom: 4px;

    border-radius: 4px;
  }
}

.img-area__controls {
  display: flex;
  align-items: center;
}

.img-area__control {
  margin-left: 10px;
  padding: 5px;
  width: 37px;
  height: 37px;

  color: $gray_light;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  svg {
    width: 100%;
    height: 100%;

    fill: currentColor;
  }

  &:first-child {
    margin-left: 0;
    padding: 9px;
    width: 37px;
    height: 37px;
  }

  &:active {
    color: $red;
  }
}

@include media-breakpoint-up(md) {
  .img-area {
    &_filled {
      .img-area__content {
        display: flex;
      }
    }
  }

  .img-area__content {
    align-items: flex-start;
  }

  .img-area__img {
    max-width: 280px;

    img {
      margin-bottom: 4px;

      border-radius: 4px;
    }
  }

  .img-area__controls {
    margin-left: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .img-area__control {

    &:hover,
    &:focus {
      color: $red;
    }

    &:active {
      color: rgba($color: $red, $alpha: 0.7);
    }
  }
}