.about_us {
	&__title {
		color: white;
		font-size: 50px;
	}

	&__subtitle {
		font-size: 38px;
		line-height: 74px;
		text-align: center;
		letter-spacing: 0.02em;

		margin-top: 100px;
	}

	&__caption {
		font-size: 16px;
		color: $gray_light;
		text-align: center;
	}

	&__get_in_touch_form {
		// margin-bottom: 32px;
		display: flex;
		flex-direction: column;

		textarea {
			min-height: 100px;

			resize: none;
		}
	}

	&__orange_bg {
		width: 70%;
		background-color: $vivid_yellow;
	}

	&__banner_container {
		display: flex;
		// align-items: center;
		// margin-bottom: 150px;
		// margin-top: 100px;
	}

	&__text_container {
		// width: 400px;
		padding: 50px 150px;
	}

	&__banner_caption {
		color: white;
		font-size: 18;
		// text-indent: 30px;
	}

	&__send {
		margin: 0 auto;

		&:focus {
			outline: none !important;
		}
	}

	&__form_container {
		// margin-top: 100px;
		margin-bottom: 100px;
	}

	&__pastors_divider {
		height: 50px;
	}

	&_1x1 {
		background-image: url("../../assets/img/pastors/pastor_1_x1.JPG");
		// background-size: contain;

		@include for_retina() {
			background-image: url("../../assets/img/pastors/pastor_1_x2.JPG");
			background-size: contain;
		}
	}

	&_1x2 {
		background-image: url("../../assets/img/pastors/pastor_2_x1.JPG");
		// background-size: contain;

		@include for_retina() {
			background-image: url("../../assets/img/pastors/pastor_2_x2.JPG");
			background-size: contain;
		}
	}

	&__banner_pastor {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		width: 426px;
		height: 600px;
	}

}

.pastor_item {
	&_image {
		width: 365px;
		height: 385px;
		// width: 300px;
		// height: 350px;
	}

	&_title {
		font-size: 24px;
		margin-top: 20px;
		text-align: center;
		margin-bottom: 0;
	}

	&_position {
		margin-top: 0;
		text-align: center;
	}

	&__red_shadow {
		-webkit-box-shadow: 15px 15px 0px 0px $red;
		-moz-box-shadow: 15px 15px 0px 0px $red;
		box-shadow: 15px 15px 0px 0px $red;
	}

	&__yellow_shadow {
		-webkit-box-shadow: 15px 15px 0px 0px $vivid_yellow;
		-moz-box-shadow: 15px 15px 0px 0px $vivid_yellow;
		box-shadow: 15px 15px 0px 0px $vivid_yellow;
	}
}

.banner_pastor_image {
	width: 426px;
	height: 400px;
	-webkit-box-shadow: 15px 15px 0px 0px $red;
	-moz-box-shadow: 15px 15px 0px 0px $red;
	box-shadow: 15px 15px 0px 0px $red;
	margin-left: -100px;

	background-image: url("../../assets/img/pastors/banner-pastor_x1.JPG");
	background-repeat: no-repeat;
	// background-size: contain;

	@include for_retina() {
		background-image: url("../../assets/img/pastors/banner-pastor_x2.JPG");
		background-size: contain;
	}

	// margin-top: 200px;
}

@include media-breakpoint-down(md) {
	.about_us {
		&__orange_bg {
			width: 100%;
			// height: 754px;
			background-color: $vivid_yellow;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px;
		}

		&__banner_container {
			display: flex;
			flex-direction: column;
			align-items: center;
			// margin-bottom: 150px;
		}

		&__text_container {
			width: auto;
			padding: 50px 20px;
		}

		&__pastors_divider {
			height: 0px;
		}

		&__banner_pastor {
			width: auto;
			margin-top: -50px;
			height: 400px;
		}

		&__subtitle {
			margin-top: 0px;
		}

		&__form_container {
			margin-top: 50px;
		}
	}

	.pastor_item {
		&_image {
			// width: 370px;
			// height: 430px;
			// width: 300px;
			// height: 350px;
			margin: auto;
			width: -webkit-fill-available;
			background-size: cover;
		}
	}

	.banner_pastor_image {
		// width: 440px;
		// height: 585px;

		width: 300px;
		height: 282px;
		-webkit-box-shadow: 15px 15px 0px 0px $red;
		-moz-box-shadow: 15px 15px 0px 0px $red;
		box-shadow: 15px 15px 0px 0px $red;
		margin: -100px 0 0 0;
	}
}