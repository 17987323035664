.comments {
  padding: 56px 0;
}

.comments__form {
  margin-bottom: 32px;

  textarea {
    min-height: 100px;

    resize: none;
  }
}

.comments__list {
  @include reset_list_style();
}

.comments__item {
  padding-bottom: 43px;

  border-bottom: 1px solid $gray_border;

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.comments__details {
  padding-right: 1rem;
}

.comments__author {
  margin: 0;
  margin-bottom: 9px;

  font-size: 18px;
  font-weight: 600;
}

.comments__text {
  margin: 0;
  margin-bottom: 12px;
}

.comments__date {
  color: $gray_light;
}

@include media-breakpoint-up(md) {
  .comments__form {
    margin-bottom: 16px;
  }
}