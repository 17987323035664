.main-footer {
	margin-top: auto;
	padding: 50px 0;
	flex-shrink: 0;
	//min-height: 100%;

	color: $white;

	background-color: $red;

	p {
		margin: 0;
	}
}

@include media-breakpoint-up(md) {
	.main-footer {
		padding: 88px 0;
	}
}
