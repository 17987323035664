.events_reports_container {
	display: grid;
	// grid-template-columns: 40% 40% 10%;
	grid-template-columns: 4fr 4fr auto;
	grid-column-gap: 20px;
}

@include media-breakpoint-down(sm) {
	.events_reports_container {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr;
		button {
			width: 100%;
		}
	}
}
