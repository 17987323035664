.dashboard {
	margin: auto;
	padding: 0 15px;
	max-width: 1280px;

	&__mobile_nav {
		display: none;
	}

	&__all_container {
		display: flex;
		height: 100%;
	}

	&__desktop_menu {
		transition: all 0.5s;
		overflow: hidden;
		position: absolute;
		background-color: white;
		border-right: 1px solid $gray_border_db;
		height: 100%;
		display: flex;
		flex-direction: column;
		@include shadow();
	}
}

.dashboard__tabs {
	z-index: 200;
	position: relative;
	width: 75px;
	border-bottom: 2px solid $gray_border_db;
	display: flex;
	flex-shrink: 1;
	min-height: 840px;
	// border: 1px solid black;

	// & > * {
	// 	// flex-shrink: 0;
	// }
}

.dashboard__tabs.account {
	min-height: 825px;
}

.dashboard__nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.dashboard__logout {
	padding: calc(30px - 0.5rem) 2rem;
	width: 200px;
	border-top: 2px solid $gray_border_db;

	&_mobile {
		@extend .dashboard__logout;
		width: 100vw;
		border-bottom: 2px solid $gray_border_db;
	}

	a {
		display: flex;
		align-items: center;
		padding: 0.5rem;

		font-size: 18px;
		color: $red;

		transition: color 0.2s ease;

		&:hover {
			text-decoration: none;
			color: $red;
		}

		&:active {
			text-decoration: none;
			color: rgba($color: $red, $alpha: 0.7);
		}

		span {
			display: block;

			&:first-child {
				margin-right: 16px;
				width: 31px;
				height: 31px;

				svg {
					width: 100%;
					height: 100%;

					fill: none;
				}
			}
		}
	}
}

.center-loading {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-spinner {
	@extend .flexy-align;
	justify-content: center;
	flex-direction: column;
}

.spinner-style {
	width: 3rem;
	height: 3rem;
	color: $red;
}

.small-spinner-style {
	width: 2.5rem;
	height: 2.5rem;
	color: $red;
}

.dashboard__content {
	padding: 50px 15px;
}

.dashboard__content_alt {
	padding: 15px 15px;
	height: 100%;
	// width: -webkit-fill-available;
	// width: -moz-available;
	// width: 100%;
}

.dashboard__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 32px;
}

.dashboard__title {
	margin: 0;

	font-size: 24px;
	font-weight: 400;
}

.dashboard__inner-tabs {
	margin-bottom: 32px;

	a {
		display: block;
		margin: 0.5rem;
		padding: 0.25rem 0;

		font-size: 18px;
		color: inherit;

		border-bottom: 2px solid transparent;

		transition: color 0.2s ease;

		&:hover {
			text-decoration: none;
			color: inherit;
		}

		&:active {
			text-decoration: none;
			color: $red;
		}

		&.active {
			border-color: $red;
		}
	}
}

.dashboard__list {
	@include reset_list_style();

	li {
		display: flex;
		align-items: center;
		padding: 25px 0;

		border-bottom: 2px solid $gray_border_db;

		span {
			margin-right: 10px;
		}
	}
}

.dashboard__controls {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.dashboard__control {
	margin-left: 10px;
	padding: 5px;
	width: 42px;
	height: 42px;

	color: $gray_light;

	background-color: transparent;
	border: none;

	transition: color 0.2s ease;

	svg {
		width: 100%;
		height: 100%;

		fill: currentColor;
	}

	&_sm {
		padding: 11px;
		width: 42px;
		height: 42px;
	}

	&:first-child {
		margin-left: 0;
	}

	&:active {
		color: $red;
	}
}

.dashboard__date {
	display: block;
	margin-left: auto;

	font-size: 12px;
	color: $gray_light;

	& + * {
		margin-left: 0;
	}
}

.dashboard__videos {
	ul {
		padding-top: 30px;
	}
}

.logout_link {
	margin-top: auto;
	border-top: 1px solid $gray_border_db;
}

@include media-breakpoint-up(md) {
	.dashboard {
		// background-color: red;
		width: calc(100% - 30px);
	}

	.dashboard__tabs {
		display: flex;

		// flex-direction: column;

		// border-right: 2px solid $gray_border_db;
	}

	.dashboard__nav {
		flex-grow: 1;
		padding: 46px 0;
		min-height: 506px;

		li {
			padding: 32.5px 0;
		}
	}

	.dashboard__inner-tabs {
		padding-top: 26px;
	}

	.dashboard__header {
		padding-top: 24px;
	}

	.dashboard__date {
		font-size: 16px;

		& + * {
			margin-left: 10px;
		}
	}
}

.page_placeholder {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		font-weight: 400;
		color: $gray_light;
		border: none;
		font-size: 20px;
	}
	&_container {
		height: 700px;
	}
}

@include media-breakpoint-down(sm) {
	.dashboard {
		width: 100%;

		&__mobile_nav {
			display: block;
		}

		&__all_container {
			height: auto;
		}
	}

	.dashboard__tabs {
		display: none;

		// flex-direction: column;

		// border-right: 2px solid $gray_border_db;
	}
}

@include media-breakpoint-up(lg) {
	.dashboard {
		margin: 20px auto;
		width: calc(100% - 30px);

		border-radius: 4px;
		// @include shadow();
	}

	.dashboard__nav {
		padding: 16px 0 16px 30px;
		min-height: 506px;

		li {
			padding: 32px 0;
		}
	}

	.dashboard__logout {
		&_mobile {
			width: auto;
		}

		// padding: 40px 46px;

		a {
			&:hover {
				text-decoration: none;
				color: rgba($color: $red, $alpha: 0.7);
			}

			&:active {
				text-decoration: none;
				color: rgba($color: $red, $alpha: 0.5);
			}
		}
	}

	.dashboard__content {
		padding: 16px 80px;
	}

	.dashboard__header {
		padding-top: 32px;
	}

	.dashboard__inner-tabs {
		a {
			&:hover {
				text-decoration: none;
				color: $red;
			}

			&:active {
				text-decoration: none;
				color: rgba($color: $red, $alpha: 0.7);
			}
		}
	}

	.dashboard__control {
		&:hover,
		&:focus {
			color: $red;
		}

		&:active {
			color: rgba($color: $red, $alpha: 0.7);
		}
	}

	.dashboard__date {
		& + * {
			margin-left: 40px;
		}
	}
}

.dashboard-action-button {
	display: block;
	margin-left: auto;
	margin-right: 15px;
	padding: 0;
	width: 32px;
	height: 32px;
	color: #a1a1a1;
	background-color: transparent;
	border: none;
	transition: color 0.2s ease;
	&:hover {
		color: $red;
	}
	svg {
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}

.refund-info {
	color: $red;
}
