.post {
  padding-bottom: 56px;
}

.post__header {
  padding-bottom: 40px;

  border-bottom: 1px solid $gray_border;
}

.post__title {
  margin-bottom: 24px;
}

.post__date {
  display: block;
  margin-bottom: 20px;

  color: $gray_light;
}

.post__content {
  padding: 40px 0;

  img {
    margin-bottom: 32px;
    width: 100%;
    height: auto;

    border-radius: 8px;
  }

  *+img {
    margin-top: 40px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    margin-bottom: 24px;

    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 24px;
  }

  h5 {
    font-size: 22px;
  }

  h6 {
    font-size: 20px;
  }

  p {
    margin: 0;
    margin-bottom: 26px;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    color: $red;

    transition: color 0.2s ease;

    &:hover {
      text-decoration: none;
      color: $red;
    }

    &:active {
      color: rgba($color: $red, $alpha: 0.7);
    }
  }

  ul,
  ol {
    padding-left: 26px;
  }

  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  blockquote {
    display: block;
    margin: 0;
    margin-bottom: 40px;
    padding: 0.5rem;

    background-color: rgba($color: $red, $alpha: 0.05);
    border-left: 3px solid $red;

    p {
      margin: 0;
      margin-bottom: 0.75rem;

      &::before,
      &::after {
        font-size: 1.5rem;
        font-weight: 700;
      }

      &::before {
        content: "\201C";

        padding-right: 0.25em;
      }

      &::after {
        content: "\201D";

        padding-left: 0.25em;

      }
    }

    footer {
      font-size: 0.85em;
    }
  }
}

@include media-breakpoint-up(lg) {
  .post__content {
    a {
      &:hover {
        color: rgba($color: $red, $alpha: 0.7);
      }

      &:active {
        color: rgba($color: $red, $alpha: 0.5);
      }
    }
  }
}