.video__content {
	position: relative;

	&_preview {
		video {
			border-radius: 8px;
		}
	}
}

.video__controls {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;

	padding: 0 16px;
	width: 100%;

	color: $white;

	opacity: 0;

	transition: opacity 0.2s ease;

	&_show {
		z-index: 1;

		opacity: 1;
	}
}

.video__progress {
	--bar-width: 0%;
	height: 5px;

	background-color: $gray;
	border-radius: 5px;

	div {
		width: var(--bar-width, 0%);

		background-color: $red_initial;
	}
}

.video__buttons {
	display: flex;
	align-items: center;
	padding: 13px 0;
}

.video__button {
	display: block;
	padding: 0;

	background-color: transparent;
	border: none;

	transition: all 0.2s ease;

	svg {
		width: 100%;
		height: 100%;
	}

	&_play {
		margin-right: 25px;
		width: 14px;
		height: 15px;
	}

	&_fullscreen {
		margin-left: auto;
		width: 20px;
		height: 20px;

		fill: none;
	}

	&_overlay {
		position: absolute;
		top: 50%;
		left: 50%;

		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		width: 60px;
		height: 60px;

		background-color: rgba($color: $black, $alpha: 0.2);
		border-radius: 50%;
		opacity: 1;

		transform: translate(-50%, -50%);

		svg {
			margin-left: 6px;
			width: 27px;
			height: 31px;
		}

		&_hidden {
			z-index: -1;

			opacity: 0;
		}
	}

	&:active {
		opacity: 0.7;
	}
}

.video__info {
	padding: 16px 0 25px;

	&_tight {
		padding: 9px 0 40px;
	}

	span {
		color: $gray_light;
	}
}

.video__delete {
	display: flex;
	align-items: center;

	svg {
		margin-right: 6px;
		width: 32px;
		height: 32px;

		fill: currentColor;
	}

	span {
		margin-top: 4px;

		color: inherit;
	}
}

.video__name {
	margin: 0;

	font-size: 28px;

	// a {
	// 	color: red !important;
	// }
	color: $black_text;

	&_small {
		font-size: 24px;
		font-weight: 400;
	}
	&:hover {
		color: $black_text;
	}
}

.video__action {
	margin: 4px 13px 4px 0;
}

.video__social {
	display: flex;
	align-items: center;

	color: $gray_light;

	span {
		margin-right: 12px;
	}
}

.video__share {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 6px;
	width: 38px;
	height: 38px;

	border-radius: 50%;

	transition: all 0.2s ease;

	&_tw {
		background-color: $social_tw;

		svg {
			width: 24px;
			height: 19px;
		}
	}

	&_fb {
		background-color: $social_fb;

		svg {
			width: 9px;
			height: 19px;
		}
	}

	&:active {
		opacity: 0.7;
	}
}

@include media-breakpoint-up(lg) {
	.video__button {
		&:hover {
			opacity: 0.7;
		}

		&:active {
			opacity: 0.5;
		}
	}

	.video__share {
		&:hover {
			opacity: 0.7;
		}

		&:active {
			opacity: 0.5;
		}
	}
}

.video__home-page-options {
	p {
		display: flex;
		align-items: center;

		cursor: pointer;
	}

	.show {
		color: $green;
	}

	.hide {
		color: $red;
	}
}
