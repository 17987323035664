.create-post__form {
  textarea {
    height: 160px;

    resize: none;
  }
}

.create-post__controls {
  display: flex;
  align-items: center;
}

.create-post__control {
  margin-left: 10px;
  padding: 5px;
  width: 37px;
  height: 37px;

  color: $gray_light;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  svg {
    width: 100%;
    height: 100%;

    fill: currentColor;
  }

  &:first-child {
    margin-left: 0;
    padding: 9px;
    width: 37px;
    height: 37px;
  }

  &:active {
    color: $red;
  }
}

.create-post__footer {
  margin-top: 40px;
  padding: 1rem 0;

  border-top: 2px solid $gray_border_db;
}

@include media-breakpoint-up(md) {
  .create-post__controls {
    margin-left: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .create-post__control {

    &:hover,
    &:focus {
      color: $red;
    }

    &:active {
      color: rgba($color: $red, $alpha: 0.7);
    }
  }
}

.post-item-delete__control {
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;

    fill: currentColor;
  }

  span {
    margin-top: 4px;
    font-size: 13px;
    color: inherit;
  }
}