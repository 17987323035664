.navbar-dark .navbar-nav .nav-link {
	padding: 1rem;

	font-weight: 600;
	// text-transform: uppercase;
	text-align: right;
	color: $white;

	transition: color 0.2s ease;

	&:active {
		color: rgba($color: $white, $alpha: 0.7);
	}
}

.navbar-dark-alt .navbar-nav-alt .nav-link-alt {
	padding: 1rem;

	font-weight: 600;
	// text-transform: uppercase;
	text-align: right;
	color: $white;
	width: 200px;
	transition: color 0.2s ease;

	&:active {
		color: rgba($color: $white, $alpha: 0.7);
	}
}

@include media-breakpoint-up(lg) {
	.navbar-dark .navbar-nav .nav-link {
		padding: 1rem 2rem;

		&:hover {
			color: rgba($color: $white, $alpha: 0.7);
		}

		&:active {
			color: rgba($color: $white, $alpha: 0.5);
		}
	}
}

@include media-breakpoint-up(xxl) {
	.nav-item {
		&:last-child {
			.nav-link {
				padding-right: 0;
			}
		}
	}
}
