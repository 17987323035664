.main-content {
	flex: 1 0 auto;
	&:not(.main-content_index) {
		padding-top: 58px;
	}
}

@include media-breakpoint-up(md) {
	.main-content {
		&:not(.main-content_index) {
			padding-top: 90px;
		}
	}

	.main-content__inner {
		padding-top: 38px;
	}
}

@include media-breakpoint-up(lg) {
	.main-content {
		&:not(.main-content_index) {
			padding-top: 120px;
		}
	}
}
