.form-group {
	position: relative;

	margin-bottom: 24px;

	&:last-child {
		margin-bottom: 0;
	}

	&[class*="col-"] {
		.form-label {
			left: 27px; // Bootstrap 15px + our 12px
		}
	}

	&_no_bottom_margin {
		margin-bottom: 0 !important;
	}
}

.form-control {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 20px);
	padding: 14px 16px;

	font-size: 1rem;
	line-height: 1.625;
	font-weight: 400;
	color: $black_text;

	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $gray_light;
	border-radius: 4px;

	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

	&_file {
		display: none;
	}
}

.form-label {
	z-index: 10;
	position: absolute;
	top: -10px;
	left: 12px;

	display: block;
	margin: 0;
	padding: 0 7px;

	font-size: 13px;

	background-color: $white;

	&_required {
		&::after {
			content: "*";

			color: $red;
		}
	}

	&_upload {
		position: static;
		display: block;
		width: 100%;
		height: 100px;

		color: $gray_light;

		transition: color 0.2s ease;

		svg {
			width: 100%;
			height: 100%;

			fill: currentColor;
		}

		&:active {
			color: $red;
		}

		&_image {
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: left;
			padding-left: 0px;

			svg {
				width: auto;
			}

			span {
				text-align: center;
				margin-left: 3px;
			}
		}
	}
	&_less_z {
		z-index: 1;
	}
}

.custom-control {
	padding-left: 38px;
}

.create_event_description_textarea {
	min-height: 100px;
	resize: none;
}

.custom-control-label {
	cursor: pointer;

	&::before {
		top: 0;
		left: -38px;

		width: 26px;
		height: 26px;

		background-color: $white;
		border-color: $gray_light;
	}

	&::after {
		top: 8px;
		left: -31px;

		display: block;
		width: 13px;
		height: 9px;

		background-color: transparent;
		background-image: url("../img/checkbox.svg") !important;
		background-repeat: no-repeat;
		background-size: contain;
		opacity: 0;

		transition: all 0.2s ease;
	}

	&:hover {
		&::after {
			opacity: 0.3;
		}
	}
}

.custom-control-input {
	&:checked {
		& ~ .custom-control-label {
			&::before {
				background-color: $white;
				border-color: $gray_light;
			}

			&::after {
				opacity: 1;
			}
		}
	}
}

.progress {
	&__main_track {
		width: 100%;
		height: 10px;
		background-color: $gray_border_db;
	}

	&__secondary_track {
		background-color: $gray_light;
		height: 100%;
	}
}

.rlc-calendar {
	background-color: #fff;
	width: 300px !important;
	font-family: "Montserrat", $font-family-base !important;
	box-shadow: none !important;
	border: none !important;
}

.rlc-days-label {
	display: flex !important;
	justify-content: center !important;
}

.rlc-days {
	display: flex !important;
	flex-wrap: wrap !important;
	justify-content: center !important;
}

.rlc-date-details {
	justify-content: center;
}

.rlc-date-time-selects {
	display: flex;
	justify-content: center;
}

.rlc-day-end-selection,
.rlc-day-inside-selection,
.rlc-day-selected,
.rlc-day-start-selection {
	background-color: $red !important;
}

.datetimerange_picker_controls {
	display: flex;
	padding: 0 10px;
	justify-content: space-between;
}

.upload-image-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dropdown-item {
	&:active {
		background-color: $red !important;
	}
}

.disable_active_dropdown {
	&:active {
		background-color: white !important;
	}
}

.clamp_paragraph {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	-webkit-box-orient: vertical;
}

@include media-breakpoint-up(lg) {
	.form-label {
		&_upload {
			cursor: pointer;

			&:hover,
			&:focus {
				color: $red;
			}

			&:active {
				color: rgba($color: $red, $alpha: 0.7);
			}
		}
	}
}
