.swiper-pagination {
  position: absolute;
  z-index: 10;
  bottom: 40px;
  left: 50%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  display: block;
  margin: 4px 11px;
  width: 10px;
  height: 10px;

  background-color: $red;
  border-radius: 50%;

  transition: background-color 0.2s ease;
}

.swiper-pagination-bullet-active {
  background-color: $white;
}

@include media-breakpoint-up(md) {
  .swiper-pagination {
    bottom: 60px;
  }
}

@include media-breakpoint-up(lg) {
  .swiper-pagination {
    bottom: 100px;
  }
}