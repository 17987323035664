.gauge {
	&__container {
		// width: 310px;
		height: 185px;
		justify-content: flex-start;
		border-radius: 4px;
		box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		flex-grow: 1;
	}

	&__main {
		display: flex;
	}

	&__header {
		height: 55px;
		border-bottom: 1px solid #ebeff2;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;

		&_p {
			font-size: 15px;
			font-weight: 600;
			margin: 0;
		}
	}

	&__legend_container {
		width: 90%;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	&__legend_name {
		display: flex;
		// justify-content: space-evenly;
		align-items: center;
		margin-bottom: 10px;
	}

	&__legend_marker {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 5px;

		&_label {
			margin: 0;
			font-size: 10px;
		}

		&_inner {
			width: 4px;
			height: 4px;
			border-radius: 4px;
			background-color: white;
		}

		&_yellow {
			background-color: $vivid_yellow;
		}

		&_green {
			background-color: $gauge_green;
		}

		&_red {
			background-color: $gauge_red;
		}

		&_blue {
			background-color: $blue;
		}
	}

	&__filter_p {
		font-size: 12px;
		margin: 0;
		color: #6a707e;

		&_action {
			//color: $red;
			cursor: pointer;
			margin-right: 5px;
		}

		&_action.toggler {
			color: $red;
		}
	}

	&__filter_container {
		display: flex;
	}

	&__filter_item_p {
		margin: 5px 0;
		font-size: 12px;
	}

	&__members_container {
		border-radius: 4px;
		box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		// width: 445px;
		height: 405px;

		&_stats {
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
		}

		.spinner-wrapper {
			height: 330px;
		}
	}

	&__chart_container {
		padding: 10px;
	}

	&__givings_container {
		// width: 405px;
		// height: 215px;
		justify-content: flex-start;
		border-radius: 4px;
		box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
		border-radius: 4px;

		&_donut {
			width: 200px;
			height: 240px;
			display: flex;
			// justify-content: space-between;
			align-items: center;
		}
		.gauge__main {
			border: red;
			height: 240px;
		}
	}
}

.recent_activities {
	&__container {
		// width: 635px;
		height: 100%;
		border-radius: 4px;
		box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
		border-radius: 4px;
	}

	&__items {
		padding: 10px;
		max-height: 460px;
		overflow-y: auto;
		height: 100%;
	}
	&__empty {
		height: 100%;
		display: flex;
	}
	&__empty_placeholder {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.recent_activity_item {
	border-radius: 4px;
	-webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.07);
	-moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	// display: flex;
	display: grid;
	grid-template-columns: 25% 40% 25% 5%;
	gap: 0 10px;
	grid-template-areas: "activity_name name date delete";
	// align-items: center;
	// justify-content: space-around;
	height: 50px;
	margin-bottom: 10px;
	justify-items: center;
	padding-left: 10px;

	&_name {
		grid-area: activity_name;
		align-self: center;
		justify-self: flex-start;
	}

	&_user {
		display: flex;
		// max-width: 40%;
		grid-area: name;
		align-self: center;
		justify-self: flex-start;
		p {
			@extend .clamp_paragraph;
			font-size: 13px;
			color: #707683;
			margin: 0;
		}
	}

	&_date {
		grid-area: date;
		align-self: center;
		p {
			font-size: 13px;
			color: #4c5862;
			margin: 0;
		}
	}

	&_avatar {
		margin-right: 10px;
		flex-shrink: 0;
	}

	&_delete {
		grid-area: delete;
		align-self: center;
		&:hover {
			opacity: 0.8;
		}
	}
}

.donut__container {
	width: 160px;
	height: 130px;
	display: flex;
	align-items: center;
}

.dashboard__grid-container {
	display: grid;
	grid-template-columns: 4fr 6fr;
	gap: 20px 20px;
	grid-template-areas:
		"chart simple-gauges"
		"chart recent"
		"givings  recent";
}

.dashboard__simple-gauge {
	grid-area: simple-gauges;

	&-pair {
		display: flex;
		justify-content: space-between;

		.spinner-wrapper {
			height: 130px;
		}
	}
}

.dashboard__recent {
	grid-area: recent;
}

.dashboard__chart {
	grid-area: chart;
}

.dashboard__givings {
	grid-area: givings;
}

.members_chart_tooltip {
	color: white;
	background-color: rgba(0, 0, 0, 0.6);
	min-width: 40px;
	border-radius: 10px;
	text-align: center;
	position: relative;
}

.apexcharts-tooltip {
	box-shadow: none !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
	border: none !important;
	background: none !important;
}

@include media-breakpoint-down(md) {
	.dashboard__grid-container {
		// background-color: green;
		grid-template-columns: 1fr;
		grid-template-areas:
			"chart"
			"simple-gauges"
			"recent"
			"givings";
	}

	.dashboard__simple-gauge {
		&-pair {
			display: block;
		}
	}
	.recent_activity_item {
		grid-template-columns: 60% 35%;
		grid-template-rows: 1fr 1fr;
		gap: 0 10px;
		grid-template-areas: "activity_name date" "name delete";
		height: auto;
		justify-items: center;
		padding: 5px;
	}
}

// @include media-breakpoint-down(sm) {
// 	// .recent_activity_item {
// 	// 	// flex-wrap: wrap;
// 	// 	// justify-content: space-between;
// 	// }
// }
