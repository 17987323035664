.main-header {
	padding: 0.5rem 0;

	color: $white;

	transition: padding-top 0.2s ease, padding-bottom 0.2s ease,
		background-color 0.2s ease;

	&_scrolled,
	&:not(.main-header_index) {
		background-color: $red;
	}

	&_scrolled {
		@include shadow_with_inset();
	}
}

.main-header__logo {
	margin: 0;
	margin-right: 0.5rem;

	a {
		font-size: 18px;
		font-weight: 600;
		text-decoration: none;
		color: $white;
	}
}

@include media-breakpoint-up(md) {
	.main-header {
		padding: 1rem 0;

		&_scrolled {
			padding: 0.5rem 0;
		}
	}
}

.nav_bar_header_avatar {
	padding: 0 1rem;
}

.nav_bar_header_avatar_collapsed {
	display: none;
}

@include media-breakpoint-up(lg) {
	.main-header {
		padding: 1.9rem 0;

		&_scrolled {
			padding: 0.5rem 0;
		}

		.search-bar {
			width: 30%;
		}
	}

	.main-header__logo {
		a {
			font-size: 24px;
		}
	}
}

@include media-breakpoint-down(md) {
	.nav_bar_header_avatar {
		padding: 0;
	}
}
@include media-breakpoint-down(sm) {
	.nav_bar_header_avatar_full {
		display: none;
	}
	.nav_bar_header_avatar_collapsed {
		display: block;
	}
}

// @include media-breakpoint-down(sm) {
// 	.main-header {
// 		background-color: $red;
// 	}
// }
