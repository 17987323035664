.videos {
	padding-top: 38px;
	padding-bottom: 110px;
}

.videos__list {
	@include reset_list_style();
}

.videos__item {
	margin-bottom: 90px;

	&:last-child {
		margin-bottom: 0;
	}
}

.react-player-wrapper {
	position: relative;
	padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
	// padding-top: 177%;
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
}

.react-player-round-edges {
	border-radius: 8px;
	overflow: hidden;
}

@include media-breakpoint-up(md) {
	.videos {
		padding-top: 0;
	}
}
