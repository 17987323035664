// Close btn
.close {
	color: inherit;

	transition: all 0.2s ease;

	svg {
		width: 15px;
		height: 15px;
	}
}

.modal {
	overflow-y: auto;

	.btn {
		min-width: 146px;

		&_theme {
			&_transparent {
				min-width: initial;
			}
		}
	}

	textarea {
		min-height: 183px;

		resize: none;
	}

	&_thankyou {
		.modal-dialog {
			max-width: 580px;
		}

		.modal-content {
			padding: 0;

			border-radius: 4px;
		}

		.modal-header {
			margin: 0;
			padding: 25px;

			color: $white;

			background-color: $red;
			border: none;
		}

		.modal-body {
			margin: 0;
			max-width: initial;
			min-height: 200px;

			text-align: center;

			box-shadow: none;
		}

		.modal-subtitle {
			font-weight: 600;
		}
	}
}

.modal-dialog {
	margin: 0 auto;
	width: 90%;
	max-width: 890px;
}

.modal-content {
	border-radius: 2px;
}

.modal-header {
	border: none;
}

.modal-compact {
	max-width: 600px;
}

.modal-body {
	margin: 0 auto;
	width: 100%;
	max-width: 502px;

	&_column {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.modal-group {
	& + .modal-group {
		margin-top: 30px;
		padding-top: 30px;

		border-top: 1px solid $gray_border;
	}
}

.modal-footer {
	margin: 0 auto;
	width: 100%;
	// max-width: 502px;
	flex-wrap: nowrap;

	border: none;
}

.modal-subtitle {
	margin-bottom: 8px;

	font-size: 24px;

	&_big {
		margin-bottom: 27px;

		font-size: 26px;
	}
}

.modal-list {
	padding: 1rem;
}

.modal-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 56px;
	padding: 34px 15px 52px !important;

	text-align: center;

	border-radius: 4px;

	&_shadow {
		@include shadow();
	}

	.btn {
		margin-top: auto;
	}
}

.modal-icon {
	display: block;
	margin-bottom: 20px;
	width: 120px;
	height: 120px;

	border-radius: 50%;
}

.modal-text {
	margin: 0;
	margin-bottom: 16px;

	font-weight: 300;
	color: $gray_light;
}

.modal-progress {
	width: 100%;
}

.modal-progress__result {
	display: block;
	margin-bottom: 20px;

	font-size: 20px;
}

.modal-progress__bar {
	margin-bottom: 24px;

	background-color: $progress_bg;
	border-radius: 0;

	.progress-bar {
		background-color: $gray_light;
	}
}

.modal_section_divider {
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: $black_text;
	margin-bottom: 24px;
}

@include media-breakpoint-up(md) {
	.modal-content {
		padding: 15px 30px;
	}

	.modal-header {
		margin-bottom: 10px;
	}

	.modal-body {
		margin-bottom: 16px;
		padding: 49px;
		min-height: 393px;

		border-radius: 4px;
		@include shadow();
	}

	.modal-footer {
		padding-right: 0;
	}

	.modal-subtitle {
		font-size: 24px;

		&_big {
			font-size: 32px;
		}
	}

	.modal-item {
		flex: 1 1 calc(50% - 10px);
		margin-bottom: 15px;
		padding: 20px 39px !important;
		max-width: initial;

		&:nth-child(odd) {
			margin-right: 20px;
		}

		&:nth-last-child(-n + 2) {
			margin-bottom: 0;
		}
	}
}

@include media-breakpoint-up(lg) {
	.close {
		&:hover {
			color: inherit;

			transform: scale(1.2);
		}
	}
}

@include media-breakpoint-up(xl) {
	.modal-body {
		padding: 49px 87px;
	}
}
