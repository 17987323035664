.user-cards {
  margin: auto;
  max-width: 830px;
}

.user-cards__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.user-cards__title {
  margin: 0;

  font-size: 18px;
  font-weight: 400;
}

.user-cards__list {
  @include reset_list_style();

  flex-wrap: nowrap;
  margin-bottom: 50px;

  overflow: auto;
}

.user-cards__item {
  align-items: center;
  padding: 30px 0;
  width: 100%;
  min-width: 660px;

  border-bottom: 2px solid $gray_border_db;
}

.user-cards__type {
  display: inline-flex;
  align-items: center;
  text-align: left;
  img{
    max-height: 50px;
    width: auto;
  }
}

.user-cards__number {
  padding-right: 15px;

  text-align: right;
}

.user-cards__default {
  padding: 0;
  padding-right: 20px;

  text-align: right;
  color: $blue;

  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 130px;

    text-align: center;
    color: inherit;

    background-color: transparent;
    border: none;

    transition: opacity 0.2s ease;

    svg {
      margin-left: 8px;

      width: 13px;
      height: 9px;

      fill: currentColor;
    }

    &:active,
    &:focus {
      opacity: 0.7;
      outline: none;
    }
  }

  &_active {
    font-weight: 300;
    color: $green;
  }
}

.user-cards__delete {
  display: block;
  margin-left: auto;
  margin-right: 15px;
  padding: 0;
  width: 32px;
  height: 32px;

  color: $gray_light;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  svg {
    width: 100%;
    height: 100%;

    fill: currentColor;
  }

  &:active {
    color: $red;
  }
}

.user-cards__notice {
  margin: 16px 0 52px;
}

.user-cards__payments {
  @include reset_list_style();
}

.user-cards__payment {
  margin-bottom: 40px;
  padding-bottom: 30px;

  border-bottom: 2px solid $gray_border_db;

  &:last-child {
    border-bottom: none;
  }
}

@include media-breakpoint-up(md) {
  .user-cards {
    padding: 76px 15px 55px;
  }

  .user-cards__payment {
    border-bottom: none;
  }
}

@include media-breakpoint-up(lg) {
  .user-cards {
    padding: 44px 15px 55px;
  }

  .user-cards__header {
    margin-bottom: 42px;
  }

  .user-cards__delete {
    margin-right: 6px;

    &:hover {
      color: $red;
    }

    &:active {
      color: rgba($color: $red, $alpha: 0.7);
    }
  }

  .user-cards__payment {
    align-items: center;
    padding: 0;

    .user-cards__delete {
      margin-right: 31px;
    }
  }

  .user-cards__field {
    margin-bottom: 0;
  }
}