.title {
  margin: 0;

  font-family: "Montserrat", $font-family-base;
  font-weight: 700;

  &_size {
    &_h2 {
      font-size: 30px;
    }

    &_h3 {
      font-size: 20px;
      font-weight: 400;
    }
  }
}

@include media-breakpoint-up(md) {
  .title {
    &_size {
      &_h2 {
        font-size: 36px;
      }

      &_h3 {
        font-size: 24px;
      }
    }
  }
}