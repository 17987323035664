.articles {
  padding-top: 75px;
  padding-bottom: 75px;
}

.articles__item {
  padding: 15px;
  min-height: 300px;

  text-align: center;
  color: $white;

  &_story {
    .articles__inner {
      background-image: url("../img/article_strory.jpg");

      @include for_retina() {
        background-image: url("../img/article_strory@2x.jpg");
      }
    }
  }

  &_covid {
    .articles__inner {
      background-image: url("../img/article_covid.jpg");

      @include for_retina() {
        background-image: url("../img/article_covid@2x.jpg");
      }
    }
  }

  &_online {
    .articles__inner {
      background-image: url("../img/article_online.jpg");

      @include for_retina() {
        background-image: url("../img/article_online@2x.jpg");
      }
    }
  }
}

.articles__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 50px;
  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  a {
    margin-top: auto;
  }
}

.artilces__name {
  margin: 0;
  margin-bottom: 80px;

  font-size: 32px;
  font-weight: 700;
}

@include media-breakpoint-up(md) {
  .articles {
    padding: 82px 0;
  }

  .articles__list {
    padding: 0 0.5rem;
  }

  .articles__item {
    padding: 0.5rem;
  }
}

@include media-breakpoint-up(xl) {
  .articles__item {
    min-height: calc(413px + 1rem);
  }

  .articles__inner {
    padding: 169px 20px 54px;
  }
}