.post-item {
  padding-bottom: 24px;

  border-bottom: 1px solid $gray_border;

  p {
    margin: 0;
    margin-bottom: 10px;

    color: $gray_light;
  }
}

.post-item__title {
  margin-bottom: 8px;
}

.post-item__date {
  display: block;
  margin-bottom: 12px;

  color: $gray_light;
}

.post-item__img {
  display: block;
  margin-bottom: 1rem;
  width: 100%;

  border-radius: 8px;
}

@include media-breakpoint-up(md) {
  .post-item {
    &_inline {
      &:nth-child(odd) {
        padding-left: 0;
      }

      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .post-item {
    &_inline {
      &:nth-child(odd) {
        padding-left: 15px;
      }

      &:nth-child(even) {
        padding-right: 15px;
      }

      &:nth-child(3n + 1) {
        padding-left: 0;
      }

      &:nth-child(3n + 3) {
        padding-right: 0;
      }
    }
  }
}