.link {
  display: inline-flex;
  align-items: center;
  padding: 0;

  text-decoration: none;
  color: $red;

  background-color: transparent;
  border: none;

  transition: color 0.2s ease;

  &:hover {
    text-decoration: none;
    color: $red;
  }

  &:active {
    color: rgba($color: $red, $alpha: 0.7);
  }

  &_theme {
    &_white {
      color: $white;

      &:hover {
        color: $white;
      }

      &:active {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .link {
    &:hover {
      color: rgba($color: $red, $alpha: 0.7);
    }

    &:active {
      color: rgba($color: $red, $alpha: 0.5);
    }

    &_theme {
      &_white {
        &:hover {
          color: rgba($color: $white, $alpha: 0.7);
        }

        &:active {
          color: rgba($color: $white, $alpha: 0.5);
        }
      }
    }
  }
}