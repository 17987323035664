.btn {
	padding: 1em;
	min-width: 128px;

	line-height: 1;
	color: $white;

	background-color: $red;

	&:hover {
		color: $white;

		background-color: $red;
	}

	&:active {
		color: $white;
		background-color: lighten($color: $red, $amount: 7%);
	}

	&_clean {
		background-color: transparent;
		border: none;
	}

	&_theme {
		&_white {
			color: $red;

			background-color: $white;
			border-color: $red;

			&:hover {
				color: $red;

				background-color: $white;
			}

			&:active {
				color: $white;

				background-color: lighten($color: $red, $amount: 7%);
			}
		}

		&_transparent {
			padding: 1em 0;
			width: auto;
			min-width: initial;

			color: $red;

			background-color: transparent;
			border-color: transparent;

			transition: all 0.2s ease;

			&:hover {
				color: $red;

				background-color: $white;
			}

			&:active {
				color: lighten($color: $red, $amount: 7%);

				background-color: $white;
			}
		}
	}

	&_plus {
		display: inline-flex;
		align-items: center;

		&::before {
			content: "\00FF0B";

			margin-right: 12px;

			font-size: 1.5em;
		}
	}
	&_compact {
		padding: 0.5em;
		min-width: auto;
	}
}

.button_switch {
	&_container {
		width: 100%;
		height: calc(1.5em + 0.75rem + 20px);
		display: flex;
		border: 1px solid $gray_light;
		border-radius: 0.25rem;
	}
	&_button {
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: none;
		&:first-child {
			border-right: 1px solid $gray_light;
		}
		&_active {
			color: $red;
		}
	}
}

.pagination_btn {
	.page-item.active {
		.page-link {
			background-color: $red;
			border-color: $red;
			color: #ffffff;
		}
	}
	.page-item {
		.page-link {
			color: $red;
		}
	}
	&_disabled {
		button {
			color: $gray !important;
		}
	}
}

@include media-breakpoint-up(lg) {
	.btn {
		&:hover {
			color: $white;

			background-color: lighten($color: $red, $amount: 7%);
		}

		&:focus {
			box-shadow: 0 0 0 0.2rem rgba($color: $red, $alpha: 0.25);
		}

		&:active {
			color: $white;

			background-color: $red;
			box-shadow: 0 0 0 0.2rem rgba($color: $red, $alpha: 0.25);
		}

		&_theme {
			&_white {
				&:hover {
					color: $white;

					background-color: lighten($color: $red, $amount: 7%);
				}

				&:active {
					color: $white;

					background-color: $red;
					box-shadow: 0 0 0 0.2rem rgba($color: $red, $alpha: 0.25);
				}
			}

			&_transparent {
				&:hover,
				&:focus {
					color: $red;

					background-color: $white;
					box-shadow: none;
					outline: none;

					opacity: 0.7;
				}

				&:active {
					color: $red;

					background-color: $white;

					opacity: 0.5;
				}
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.header_button {
		width: 100%;
		&:last-child {
			margin-top: 10px;
		}
	}
}

.rounded-btn {
	padding: 1em;
	width: 250px;
	height: 50px;
	line-height: 1;
	border-radius: 25px;
	border: 1px solid #333333;
	color: #4f4f4f;
	font-size: 16px;
	font-weight: 600;

	background-color: white;

	&:hover {
		color: $white;
		outline: none !important;
		background-color: $red;
	}

	&:active {
		color: $white;
		outline: none !important;
		background-color: lighten($color: $red, $amount: 7%);
	}
}
