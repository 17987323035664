.username {
	&__container {
		position: relative;
	}
	&__label {
		width: 40px;
		height: 10px;
		position: absolute;
		margin-top: -10px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			font-size: 8px;
			color: white;
		}

		background-color: #7c8dfb;

		&_deacon {
			background-color: #5c6496;
		}
		&_youth {
			background-color: #ff8686;
		}
		&_choir {
			background-color: #7c8dfb;
		}
	}
}

.members {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
		padding-top: 32px;
	}
	&__search_field {
		height: 50px;
		border: none;
		border-bottom: 1px solid $gray_light;
		padding-right: 21px;
		&_icon {
			position: absolute;
			right: 0;
			top: 15px;
		}
		&_container {
			position: relative;
		}
	}

	&__search_field_55 {
		@extend .members__search_field;
		height: 55px;
	}

	&__actions {
		display: flex;
	}
	&__toggler {
		display: flex;
		cursor: pointer;
		p {
			margin: 0 10px 0 0;
		}
	}
}

.roles {
	&_item_controls {
		@extend .dashboard__controls;
		justify-content: flex-end;
	}
}

.members_table_container {
	overflow-x: auto;
}

.members_table_body_checkmark_align {
	width: 50px;
	label {
		margin-top: -12px;
	}
}

.members_table_header_checkmark_align {
	width: 50px;
	label {
		margin-top: -25px;
	}
}

.selected_counter {
	margin: 0 20px 0 0;
}

.action_dropdown_items {
	&:last-child {
		// label {
		.b-contain {
			margin-bottom: 0;
			margin: 10px 10px;
		}
		// }
	}
}

@include media-breakpoint-down(sm) {
	.members {
		&__header {
			flex-direction: column;
		}
		&__actions {
			flex-direction: column;
		}
		&__search_field {
			width: 100%;
			&_container {
				position: relative;
				margin-right: 0;
				margin-bottom: 20px;
				width: 80vw;
			}
		}
	}
}
