@import "react-big-calendar/lib/sass/styles";

.rbc-calendar {
	height: 95%;
}

.rbc-header {
	border-left: none !important;
	height: 50px;
	text-align: left;
	color: $gray_placeholder;
	font-size: 16px;
}

.rbc-month-view {
	border: none;
}

.rbc-month-row {
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	&:last-child {
		border-bottom: 1px solid #ddd;
	}
}

.rbc-event {
	background-color: $red;
}

.scheduling_event_title {
	margin: 0;
	font-size: 13px;
}

.scheduling_event_mark {
	width: 5px;
	height: 15px;
	border-radius: 3px;
	margin-right: 5px;
}

.rbc-event.rbc-selected {
	background-color: lighten($color: $red, $amount: 7%);
}
