// Colors

$black_text: #333333;

$blue: #2d9cdb;

$gray: #b3b3b3;
$gray_border: #dddddd;
$gray_border_db: #f8f8f8;
$gray_border_chat: #f2f2f2;
$gray_light: #a1a1a1;
$gray_placeholder: #828282;
$green: #1c9c38;

$red: #9c1c21;
$red_initial: #ff0000;

$yellow: #f9bf21;
$vivid_yellow: #fa9d32;

$gauge_green: #2ed47a;
$gauge_red: #f7685b;

$social_tw: #55acee;
$social_fb: #4e71a8;
$social_yt: #ff0000;

$card_mc_red: #e36666;
$card_mc_yellow: #f2e985;

$progress_bg: #f8f8f8;
