.all-posts__list {
  padding: 0;
  list-style: none;
}

.all-posts__item {
  margin-bottom: 50px;
}

@include media-breakpoint-up(md) {
  .all-posts__list {
    margin: 0;
  }

  .all-posts {
    padding-top: 63px;
    padding-bottom: 79px;
  }

  .all-posts__item {
    margin-bottom: 62px;
  }

  .all-posts__title {
    margin-bottom: 88px;
  }
}

@include media-breakpoint-up(md) {
  .all-posts__item {
    margin-bottom: 72px;
  }
}