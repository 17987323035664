@mixin reset_list_style() {
  margin: 0;
  padding: 0;

  list-style: none;
}

@mixin string_elipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;

  overflow: hidden;
}

@mixin visually_hidden() {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@mixin for_retina() {

  @media (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
    @content;
  }
}

@mixin shadow_with_inset() {
  box-shadow:
    0 0.5rem 1rem 0 rgba($color: $black, $alpha: 0.05),
    inset 0 -1px 0 0 rgba($color: $black, $alpha: 0.1);
}

@mixin shadow() {
  box-shadow: 0 1px 26px 0 rgba($color: $black, $alpha: 0.06);
}